import axios from "axios";
import { defineStore } from "pinia";

export const useReportsStore = defineStore("reports", {
  state: () => ({
    _status: null,
    _error: null,
    _reports: null,
    _pageCount: 0,
    _page: 0,
  }),

  getters: {
    isLoading: (state) => state._status === "loading",
    isReady: (state) => state._status === "ready",
    isError: (state) => state._status === "error",
    error: (state) => state._error,
    reports: (state) => state._reports,
    pageCount: (state) => state._pageCount,
    page: (state) => state._page,
  },

  actions: {
    async getReports(value = "productor", userId = null, pageNumber = 1) {
      let url = `${process.env.VUE_APP_API_URL}/api/sales-reports/?type=${value}&page=${pageNumber}&user=${userId}`;
      this.changeStatus("loading");
      try {
        if (userId) {
          const res = await axios.get(
            url,
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );
          this._pageCount = Math.ceil(res.data.count / 10);
          this._page = pageNumber;
          this._reports = res.data.results;
          this.changeStatus("ready");
        } else {
          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              "Content-Type": "multipart/form-data",
            },
          });
          this._pageCount = Math.ceil(response.data.count / 10);
          this._page = pageNumber;
          this._reports = response.data.results;
          this.changeStatus("ready");
        }
      } catch (error) {
        this.changeStatus("error");
        console.error("Error fetching reports:", error);
      }
    },
    async toggleReportVisibility(reportId, isVisible) {
      try {
        const response = await axios.patch(
          `https://apistaging.skillpering.com/api/sales-reports/`, 
          {
            id: reportId,
            visible: isVisible
          },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('token')}`,
              'Content-Type': 'application/json'
            }
          }
        );

        // Update local state if needed
        return response.data;
      } catch (error) {
        console.error('Error toggling report visibility:', error);
        throw error;
      }
    },
    async payReport(reportId) {
      this.changeStatus("loading");

      try {
        await axios.post(
          `${process.env.VUE_APP_API_URL}/api/sales-payment/`,
          { sales_report_id: reportId },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          }
        );

        this.changeStatus("ready");
      } catch (error) {
        this.changeStatus("error");
        console.error("Error processing payment:", error);
        throw error;
      }
    },
    changeStatus(status, error = null) {
      this._status = status;
      if (status === "error") this._error = error;
    },
  },
});
