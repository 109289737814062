import axios from "axios";
import { defineStore } from "pinia";

export const useForm = defineStore({
  id: "form",
  state: () => ({
    _status: null,
    _error: null,
    _token: sessionStorage.getItem("token") || null,
    _user: null,
  }),
  getters: {
    isLoading: (state) => state._status === "loading",
    isReady: (state) => state._status === "ready",
    isError: (state) => state._status === "error",
    error: (state) => state._error,
    token: (state) => state._token,
    isAuthenticated: (state) => !!state._token,
    user: (state) => state._user,
  },
  actions: {
    async login(payload) {
      this.changeStatus("loading");
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/api/auth0-login/`,
          payload
        );

        if (response.status === 200) {
          this._token = response.data.access_token;
          sessionStorage.setItem("token", response.data.access_token);
          sessionStorage.setItem("refresh_token", response.data.refresh_token);
          
          try {
            const payload = JSON.parse(atob(response.data.access_token.split('.')[1]));
            this._user = {
              sub: payload.sub,
              email: payload.email,
              name: payload.name,
              picture: payload.picture,
              email_verified: payload.email_verified,
              given_name: payload.given_name,
              family_name: payload.family_name,
              nickname: payload.nickname
            };
          } catch (e) {
            console.error('Error parsing token:', e);
          }
          
          this.changeStatus("ready");
          return true;
        }
        return false;
      } catch (error) {
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("refresh_token");
        this._token = null;
        this._user = null;
        this.changeStatus("error", error.response?.data || error.message);
        throw error;
      }
    },

    async refreshToken() {
      if (!this._token) return false;
      
      this.changeStatus("loading");
      try {
        const refreshToken = sessionStorage.getItem("refresh_token");
        if (!refreshToken) {
          this.logout();
          return false;
        }

        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/api/auth0-refresh-token/`,
          {
            refresh_token: refreshToken,
          }
        );

        if (response.status === 200) {
          this._token = response.data.access_token;
          sessionStorage.setItem("token", response.data.access_token);
          
          try {
            const payload = JSON.parse(atob(response.data.access_token.split('.')[1]));
            this._user = {
              sub: payload.sub,
              email: payload.email,
              name: payload.name,
              picture: payload.picture,
              email_verified: payload.email_verified,
              given_name: payload.given_name,
              family_name: payload.family_name,
              nickname: payload.nickname
            };
          } catch (e) {
            console.error('Error parsing token:', e);
          }
          
          this.changeStatus("ready");
          return true;
        }
        return false;
      } catch (error) {
        this.logout();
        return false;
      }
    },

    logout() {
      this._token = null;
      this._user = null;
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("refresh_token");
      this.changeStatus("ready");
      window.location.href = "/login";
    },

    changeStatus(status, error = null) {
      this._status = status;
      if (status === "error") this._error = error;
      else this._error = null;
    },

    setupAxiosInterceptors() {
      axios.interceptors.request.use(
        (config) => {
          const token = this._token;
          if (token) {
            config.headers.Authorization = `Bearer ${token}`;
          }
          return config;
        },
        (error) => {
          return Promise.reject(error);
        }
      );

      axios.interceptors.response.use(
        (response) => response,
        async (error) => {
          const originalRequest = error.config;
          
          if (error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            
            const success = await this.refreshToken();
            if (success) {
              originalRequest.headers.Authorization = `Bearer ${this._token}`;
              return axios(originalRequest);
            }
          }
          
          return Promise.reject(error);
        }
      );
    },
  },
});