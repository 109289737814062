<template>
  <div class="main-title">
    <h2>{{ $t("PROFILE.TITLE") }}</h2>
    <button
      v-if="!edit"
      @click="edit = !edit"
      class="btn btn-dark"
    >
      <span v-if="!edit"> {{ $t("PROFILE.EDIT") }} </span>
    </button>
    <button
      v-else
      class="btn btn-primary"
      @click="save"
    >
      <span>{{ $t("PROFILE.SAVE") }}</span>
    </button>
  </div>
  <section>
    <div class="bg-sections d-flex mb-2">
      <div class="profile">
        <button v-if="!isAuthenticated" @click="login" class="btn btn-primary">
          {{ $t("PROFILE.LOGIN") }}
        </button>
        <div v-else>
          <div class="user-details">
            <img
              :src="producerStore.user?.picture || '/default-profile.png'"
              alt="Profile Picture"
              class="profile-picture mb-3"
            />
            <h3>{{ producerStore.user?.name || producerStore.profile?.user?.email }}</h3>
            <p>{{ producerStore.profile?.user?.email }}</p>
          </div>
          <button @click="logOut" class="btn mb-3 btn-secondary">
            {{ $t("PROFILE.LOGOUT") }}
          </button>
        </div>
      </div>
      <div class="mx-4 w-full">
        <p class="text-secondary" v-text="$t('PROFILE.INFO')"></p>
        <span class="">Contactenos si desea cambiar esta informacion</span>
        <ProfileForm :form-data="{
          given_name: producerStore.user?.given_name,
          family_name: producerStore.user?.family_name,
          email: producerStore.profile?.user?.email,
          phone: producerStore.profile?.user?.phone
        }" :edit="edit" />
      </div>
    </div>

    <div class="w-full">
      <CompanyForm :edit="edit"/>
    </div>
  </section>
</template>

<script>
export default {
  name: 'UserProfile',
}
</script>

<script setup>
import CompanyForm from "@/components/forms/CompanyForm.vue"
import ProfileForm from "@/components/forms/ProfileForm.vue"
import useProducerStore from "@/stores/Producer"
import formStore from "@/stores/form"
import { onMounted, ref, computed } from "vue"
import { useRouter } from 'vue-router'

const router = useRouter()
const producerStore = useProducerStore()
const form = formStore()
const edit = ref(false)

const isAuthenticated = computed(() => !!sessionStorage.getItem('token'))

const login = () => {
  router.push('/login')
}

const logOut = () => {
  form.logout()
}

onMounted(async () => {
  const token = sessionStorage.getItem('token')
  if (!token) {
    router.push('/login')
    return
  }

  // Get user from token
  try {
    const payload = JSON.parse(atob(token.split('.')[1]))
    if (payload.sub) {
      await producerStore.getUserProfile(payload.sub)
      await producerStore.getProducer(payload.sub)
    }
  } catch (error) {
    console.error('Error decoding token:', error)
    form.logout()
  }
})

const save = async () => {
  edit.value = false
  await producerStore.saveProfile({
    company_name: producerStore.producer?.company_name,
    entity_type: producerStore.producer?.entity_type,
    registration_number: producerStore.producer?.registration_number,
    tax_id_number: producerStore.producer?.tax_id_number,
    vat_id_number: producerStore.producer?.vat_id_number,

    city: producerStore.profile?.city,
    address: producerStore.profile?.address,
    country: producerStore.profile?.country,
    state: producerStore.profile?.state,
    zip_code: producerStore.profile?.zip_code,
    apartment: producerStore.profile?.apartment,
    payload_email: producerStore.profile?.payload_email,
    account_type: producerStore.profile?.account_type,

    request_type: 'producer',
  })
}
</script>
