<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light w-100 bg-white ">
    <a class="navbar-brand" href="#">
      <img :src="storeForm.user?.picture || defaultAvatar" alt="Profile Picture" class="profile-picture">
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav ms-auto">
        <li class="nav-item dropdown" v-if="storeForm.token">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {{ storeForm.user?.name || 'Admin' }}
          </a>
          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
            <li><router-link to="/profile" class="dropdown-item" href="#">{{ $t('PROFILE.PROFILE') }}</router-link></li>
            <li><a class="dropdown-item" href="#">Opciones</a></li>
            <li><hr class="dropdown-divider"></li>
            <li><a class="dropdown-item" href="#" @click="logout">Cerrar Sesión</a></li>
          </ul>
        </li>
        <li class="nav-item" v-else>
          <a class="nav-link" href="#" @click="$emit('login')">Login</a>
        </li>
      </ul>
    </div>
  </nav>
</template>


<script setup>
import { useForm } from "@/stores/form";

const storeForm = useForm();
const defaultAvatar = 'https://cdn.auth0.com/avatars/default.png';

const logout = () => {
  storeForm.logout();
}
</script>

<style scoped>
.navbar {
  padding: 10px 20px;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 10000;
}
.nav-link {
  color: #343a40 !important;
}
.nav-link:hover {
  color: #007bff !important;
}
.profile-picture {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}
.profile-picture-sm {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-right: 8px;
}
</style>