import { createRouter, createWebHistory } from "vue-router"
import AccountStatus from "@/views/AccountStatus.vue"
import AffiliateProducts from "@/views/AffiliateProducts.vue"
import AffiliateSalesList from "@/views/AffiliateSalesList.vue"
import AffiliatesList from "@/views/AffiliatesList.vue"
import allProducts from "@/views/AllProducts.vue"
import Home from "@/views/HomeMain.vue"
import NotFound from "@/views/NotFound.vue"
import PayoutsList from "@/views/PayoutsList.vue"
import AddProduct from "@/views/Product/AddProduct.vue"
import EditProduct from "@/views/Product/EditProduct.vue"
import AppProfile from "@/views/Profile.vue"
import ReportsView from "@/views/ReportsView.vue"
import SalesList from "@/views/SalesList.vue"
import ServerError from "@/views/ServerError.vue"
import Login from "@/components/AdminLogin.vue"
import { useForm } from "@/stores/form"

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: { requiresAuth: false }
  },
  {
    path: "/",
    name: "dashboard-home",
    component: Home,
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        name: "home",
        component: AffiliateProducts,
        meta: { requiresAuth: true },
      },
      {
        path: "/products",
        name: "products",
        component: allProducts,
        meta: { requiresAuth: true },
      },
      {
        path: "/sales",
        name: "sales",
        component: SalesList,
        meta: { requiresAuth: true },
      },
      {
        path: "/affiliate-sales",
        name: "affiliate-sales",
        component: AffiliateProducts,
        meta: { requiresAuth: true },
      },
      {
        path: "/affiliate-products",
        name: "affiliate-products",
        component: AffiliateSalesList,
        meta: { requiresAuth: true },
      },
      {
        path: "/addProduct",
        name: "AddProduct",
        component: AddProduct,
        meta: { requiresAuth: true },
      },
      {
        path: "/edit-product",
        name: "editProduct",
        component: EditProduct,
        meta: { requiresAuth: true },
      },
      {
        path: "/profile",
        name: "profile",
        component: AppProfile,
        meta: { requiresAuth: true },
      },
      {
        path: "/affiliates/:id?",
        name: "affiliates",
        component: AffiliatesList,
        meta: { requiresAuth: true },
      },
      {
        path: "/payouts",
        name: "payouts",
        component: PayoutsList,
        meta: { requiresAuth: true },
      },
      {
        path: "/reports",
        name: "reports",
        component: ReportsView,
        meta: { requiresAuth: true },
      },
      {
        path: "/404",
        name: "NotFound",
        component: NotFound,
        meta: { requiresAuth: true }
      },
      {
        path: "/500",
        name: "ServerError",
        component: ServerError,
        meta: { requiresAuth: true }
      },
      {
        path: "/accountStatus",
        name: "accountStatus",
        component: AccountStatus,
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404'
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const store = useForm();

  // If going to login page
  if (to.path === '/login') {
    if (store.token) {
      next('/');
      return;
    }
    next();
    return;
  }

  // For all other routes that require auth
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.token) {
      sessionStorage.setItem('redirectPath', to.fullPath);
      next('/login');
      return;
    }

    try {
      // Try to refresh token before navigation
      await store.refreshToken();
      next();
    } catch (error) {
      console.error('Auth error:', error);
      store.logout();
      next('/login');
    }
    return;
  }

  // For any other routes
  next();
});

export default router;