import axios from "axios";
import { defineStore } from "pinia";

export const usePayoutStore = defineStore("Payouts", {
  state: () => ({
    _status: null,
    _error: null,
    _data: null,
    _pageCount: 0,
    _page: 1
  }),

  getters: {
    isLoading: (state) => state._status === "loading",
    isLoadingAll: (state) => state._status === "loadingAll",
    isReadyAll: (state) => state._status === "readyAll",
    isReady: (state) => state._status === "ready",
    isError: (state) => state._status === "error",
    error: (state) => state._error,
    data: (state) => state._data,
    pageCount: (state) => state._pageCount,
    currentPage: (state) => state._page
  },

  actions: {
    async getPayouts(page = 1, producerId = null) {
      this.changeStatus("loading");

      let url = `${process.env.VUE_APP_API_URL}/api/payouts/?page=${page}`;
      
      if (producerId) {
        url += `&producer_id=${producerId}`;
      }
      try {
        const response = await axios.get(
          url,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        );
        
        this._data = response.data.results;
        this._pageCount = Math.ceil(response.data.count / 10); // Assuming 10 items per page
        this._page = page;
        this.changeStatus("ready");
      } catch (error) {
        this.changeStatus("error");
        console.error("Error fetching payouts:", error);
      }
    },

    async createPayout(amount, user) {
      this.changeStatus("loading");
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/api/payouts/`,
          {
            user: user,
            amount: amount,
          },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        );

        return response.data;
      } catch (error) {
        this.changeStatus("error", error);
        console.error("Error creating payout:", error);
        throw error;
      }
    },

    changeStatus(status, error = null) {
      this._status = status;
      if (status === "error") this._error = error;
    },
  },
});