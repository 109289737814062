<template>
    <div class="input-group">
      <label class="label" for="product">{{ props.label }}</label>
      <div class="select-container" ref="selectContainerRef">
        <div
          class="select-trigger"
          @click="handleSelectClick"
          v-if="!isSelectOpen"
        >
          <span :class="{ placeholder: !selectedProduct }">
            {{
              selectedProduct ? selectedProduct?.[props.prop_name] : `Seleccionar ${props.label}`
            }}
          </span>
          <svg class="select-arrow" viewBox="0 0 24 24" width="16" height="16">
            <path d="M7 10l5 5 5-5z" fill="currentColor" />
          </svg>
        </div>
  
        <div v-if="isSelectOpen" class="select-search-wrapper">
          <div class="select-search-wrapper">
            <input
              ref="searchInputRef"
              type="text"
              v-model="searchQuery"
              placeholder="Buscar producto"
              class="select-search-input"
              :disabled="isLoading"
            />
  
            <div class="select-options">
              <div
                v-for="product in filteredProducers"
                :key="product.id"
                class="select-option"
                :class="{ 'is-selected': selectedProduct?.id === product.id }"
                @click="selectProduct(product)"
              >
                {{ product?.[props.prop_name] }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { defineProps, defineEmits, ref, onMounted, onBeforeUnmount, computed } from "vue";
  
  const props = defineProps({
    list: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
    },
    prop_name: {
      type: String,
      required: true,
    },
  });
  
  const emit = defineEmits(["item-selected"]);
  
  // Refs
  const isSelectOpen = ref(false);
  const searchQuery = ref("");
  const selectedProduct = ref(null);
  const isLoading = ref(false);
  const selectContainerRef = ref(null);
  const searchInputRef = ref(null);
  
  // Methods
  const handleSelectClick = (event) => {
    event.stopPropagation();
    if (!isLoading.value) {
      isSelectOpen.value = !isSelectOpen.value;
      if (isSelectOpen.value) {
        setTimeout(() => {
          if (searchInputRef.value) {
            searchInputRef.value.focus();
          }
        }, 0);
      }
    }
  };
  const filteredProducers = computed(() => {
    if (!searchQuery.value) return props?.list;
    return props?.list?.filter((product) =>
      product?.[props.prop_name]?.toLowerCase()?.includes(searchQuery.value.toLowerCase())
    );
  });
  const selectProduct = (product) => {
    selectedProduct.value = product;
    isSelectOpen.value = false;
    searchQuery.value = "";
    emit("item-selected", product);
  };
  
  const handleClickOutside = (event) => {
    const selectContainer = selectContainerRef.value;
    if (selectContainer && !selectContainer.contains(event.target)) {
      isSelectOpen.value = false;
      searchQuery.value = "";
    }
  };
  
  // Lifecycle hooks
  onMounted(() => {
    document.addEventListener("click", handleClickOutside);
  });
  
  onBeforeUnmount(() => {
    document.removeEventListener("click", handleClickOutside);
  });
  </script>
  
  <style scoped>
  .select-container {
    position: relative;
    width: 100%;
  }
  
  .select-trigger {
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 0.25rem;
    background-color: white;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 38px;
  }
  
  .select-trigger:hover {
    border-color: #bbb;
  }
  
  .select-trigger.is-open {
    border-color: #0d1017;
    box-shadow: 0 0 0 1px #0d1017;
  }
  
  .select-arrow {
    transition: transform 0.2s ease;
  }
  
  .is-open .select-arrow {
    transform: rotate(180deg);
  }
  
  .placeholder {
    color: #000000;
    background-color: #ffffff;
    cursor:text
  }
  
  .select-search-wrapper {
    width: 100%;
    background: white;
    border: none;
    border-top: none;
    border-radius: 0.25rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
    z-index: 50;
  }
  .select-search-wra {
    min-height: 43px;
    width: 100%;
  }
  
  .select-search-input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #b6b6b6;
    border-radius: 0.25rem;
    outline: none;
    background: transparent;
    font-size: 1rem;
  }
  
  .select-options {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    background: white;
    border: 1px solid #ddd;
    border-top: none;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  }
  
  .select-option {
    padding: 0.5rem;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .select-option:hover {
    background-color: #f3f4f6;
  }
  
  .select-option.is-selected {
    background-color: #e5e7eb;
  }
  
  /* Make input group relative for proper z-index stacking */
  .input-group {
    position: relative;
    z-index: 1;
  }
  
  .input-group:has(.select-container) {
    z-index: 2;
  }
  </style>
  