import axios from "axios";
import { defineStore } from "pinia";

export default defineStore({
  id: "user",
  state: () => ({
    _status: null,
    _error: null,
    _token: sessionStorage.getItem("token") || null,
    _users: null,
  }),
  getters: {
    isLoading: (state) => state._status === "loading",
    isReady: (state) => state._status === "ready",
    isError: (state) => state._status === "error",
    error: (state) => state._error,
    token: (state) => state._token,
    users: (state) => state._users,
  },
  actions: {
    async getUsers() {
      this.changeStatus("loading");
      try {
        const token = sessionStorage.getItem('token');
        if (!token) throw new Error('No authentication token found');

        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/api/user-list/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        this._users = response.data
          .filter(e => e?.first_name != undefined && e?.last_name != undefined)
          .map(e => {
            const { id, username, email } = e;
            const full_name = `${e.first_name} ${e.last_name}`.trim();
            return full_name !== "" ? { id, username, email, full_name } : null;
          })
          .filter(Boolean);

        this.changeStatus("ready");
      } catch (error) {
        this.changeStatus("error");
        throw error;
      }
    },
    changeStatus(status, error = null) {
      this._status = status;
      if (status === "error") this._error = error;
    },
  },
});
