<script setup>
import { ref, onMounted, computed, onUnmounted } from "vue";
import { useReportsStore } from "../stores/Report";
import useUserStore from "../stores/User";
import { useForm } from "../stores/form"; 
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import PaginationControl from "../components/common/PaginationControl.vue";
import { useRouter } from 'vue-router';

const router = useRouter();
const form = useForm(); 
const reportsStore = useReportsStore();
const userStore = useUserStore();

const isLoading = ref(false);
const selected = ref(false);
const selectedProducer = ref(null);
const isSelectOpen = ref(false);
const searchQuery = ref("");
const processingVisibility = ref(null);
const currentPage = ref(1);
const searchInputRef = ref(null);
const selectContainerRef = ref(null);
const processingPayment = ref(null);

const isAuthenticated = computed(() => !!form.token);

const handleToggleVisibility = async (report) => {
  processingVisibility.value = report.id;

  try {
    const newVisibility = !report.visible;
    await reportsStore.toggleReportVisibility(report.id, newVisibility);
    await reportsStore.getReports(
      "productor",
      selectedProducer.value.id,
      currentPage.value
    );

    toast.success(
      `Se ha ${newVisibility ? 'habilitado' : 'ocultado'} el reporte exitosamente`,
      {
        autoClose: 3000,
        position: toast.POSITION.TOP_RIGHT,
      }
    );
  } catch (error) {
    toast.error(
      "Error al cambiar la visibilidad del informe. Por favor, intente nuevamente.",
      {
        autoClose: 5000,
        position: toast.POSITION.TOP_RIGHT,
        theme: "colored",
      }
    );
  } finally {
    processingVisibility.value = null;
  }
};

const handlePayment = async (reportId) => {
  processingPayment.value = reportId;
  try {
    await reportsStore.payReport(reportId);
    await reportsStore.getReports(
      "productor",
      selectedProducer.value.id,
      currentPage.value
    );
    toast.success("¡Pago realizado exitosamente!", {
      autoClose: 3000,
      position: toast.POSITION.TOP_RIGHT,
    });
  } catch (error) {
    toast.error("Error al procesar el pago. Por favor, intente nuevamente.", {
      autoClose: 5000,
      position: toast.POSITION.TOP_RIGHT,
      theme: "colored",
    });
  } finally {
    processingPayment.value = null;
  }
};

const formatDateDisplay = (dateString) => {
  const cleanDateString = dateString.replace(/\*/g, "");
  const date = new Date(cleanDateString);
  const months = [
    "Ene", "Feb", "Mar", "Abr", "May", "Jun",
    "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"
  ];
  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();
  return `${month} ${day}, ${year}`;
};

const filteredProducers = computed(() => {
  if (!searchQuery.value) return userStore?.users;
  return userStore?.users?.filter((user) =>
    user?.full_name?.toLowerCase()?.includes(searchQuery.value.toLowerCase())
  );
});

async function selectProducer(producer) {
  try {
    selectedProducer.value = producer;
    currentPage.value = 1;
    searchQuery.value = producer.full_name;
    isSelectOpen.value = false;
    selected.value = true;
    
    isLoading.value = true;
    await reportsStore.getReports("productor", producer.id, currentPage.value);
  } catch (error) {
    console.error("Error fetching reports:", error);
    toast.error("Error al cargar los reportes. Por favor, intente nuevamente.", {
      autoClose: 5000,
      position: toast.POSITION.TOP_RIGHT,
      theme: "colored",
    });
  } finally {
    isLoading.value = false;
  }
}

function handleSelectClick(event) {
  event.stopPropagation();
  if (!isLoading.value) {
    isSelectOpen.value = !isSelectOpen.value;
    if (isSelectOpen.value) {
      setTimeout(() => {
        if (searchInputRef.value) {
          searchInputRef.value.focus();
        }
      }, 0);
    }
  }
}

function handlePageChange(page) {
  currentPage.value = page;
  if (selectedProducer.value) {
    reportsStore.getReports("productor", selectedProducer.value.id, page);
  }
}

const handleClickOutside = (event) => {
  const container = selectContainerRef.value;
  if (container && !container.contains(event.target)) {
    isSelectOpen.value = false;
  }
};

onMounted(async () => {
  isLoading.value = true;
  try {
    // Verify authentication
    if (!isAuthenticated.value) {
      router.push('/login');
      return;
    }
    
    // Verify token exists
    const token = sessionStorage.getItem('token');
    if (!token) {
      router.push('/login');
      return;
    }

    // Load users list
    await userStore.getUsers();
    // Add click outside listener
    document.addEventListener('click', handleClickOutside);
  } catch (error) {
    console.error("Error loading data:", error);
    toast.error("Error cargando datos. Por favor, intente nuevamente.", {
      autoClose: 5000,
      position: toast.POSITION.TOP_RIGHT,
      theme: "colored",
    });
  } finally {
    isLoading.value = false;
  }
});

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);
});
</script>

<template>
  <section>
    <h2 class="">Reportes</h2>
    <div class="input-group">
      <label class="label" for="producer">Usuario</label>
      <div class="select-container" ref="selectContainerRef">
        <div
          class="select-trigger"
          @click="handleSelectClick"
          v-if="!isSelectOpen"
        >
          <span :class="{ placeholder: !selectedProducer }">
            {{
              selectedProducer
                ? selectedProducer.full_name
                : "Seleccionar usuario"
            }}
          </span>
          <svg class="select-arrow" viewBox="0 0 24 24" width="16" height="16">
            <path d="M7 10l5 5 5-5z" fill="currentColor" />
          </svg>
        </div>

        <div v-if="isSelectOpen" class="select-search-wra">
          <div class="select-search-wrapper">
            <input
              ref="searchInputRef"
              type="text"
              v-model="searchQuery"
              placeholder="Buscar productor"
              class="select-search-input"
              :disabled="isLoading"
            />

            <div class="select-options">
              <div
                v-for="producer in filteredProducers"
                :key="producer.id"
                class="select-option"
                :class="{ 'is-selected': selectedProducer?.id === producer.id }"
                @click="selectProducer(producer)"
              >
                {{ producer.full_name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <table class="w-full">
      <thead>
        <tr class="reporte-header">
          <th class="column">Período Semanal</th>
          <th class="column">Total</th>
          <th class="actions-column-header">Acciones</th>
        </tr>
      </thead>
      <div v-if="!selected">
        <div class="selection-box">
          <h2>Selecciona un usuario</h2>
        </div>
      </div>
      <tbody v-else-if="selected && reportsStore?.reports?.length">
        <tr
          v-for="(report, index) in reportsStore.reports"
          :key="index"
          :class="[
            index % 2 === 0 ? 'reporte-item' : 'reporte-it',
            'reporte-row',
          ]"
        >
          <td class="column">
            {{
              `${formatDateDisplay(report.date_from)} - ${formatDateDisplay(
                report.date_to
              )}`
            }}
          </td>
          <td class="column">{{ report.total ? report.total : " ---" }}</td>
          <td class="actions-column">
            <button
              class="visibility-toggle-button"
              @click="handleToggleVisibility(report)"
              :disabled="processingVisibility === report.id"
            >
              <i
                class="fa fa-eye"
                :class="report.visible ? 'fa-eye-slash' : 'fa-eye'"
                aria-hidden="true"
              ></i>
              {{
                processingVisibility === report.id
                  ? "Procesando..."
                  : report.visible
                  ? "Ocultar"
                  : "Mostrar"
              }}
            </button>
            <button
              class="pay-button"
              v-if="!report.payed"
              @click="handlePayment(report.id)"
              :disabled="processingPayment === report.id"
            >
              <i class="fa fa-credit-card" aria-hidden="true"></i>
              {{ processingPayment === report.id ? "Procesando..." : "Pagar" }}
            </button>
            <a class="search-button" :href="report.file" download>
              <i class="fa fa-download" aria-hidden="true"></i>
              Descargar reporte
            </a>
          </td>
        </tr>
      </tbody>
      <div v-else>
        <div class="selection-box">
          <h2>No hay datos disponible</h2>
        </div>
      </div>
    </table>

    <PaginationControl
      v-if="selected && reportsStore.reports?.length"
      :current-page="currentPage"
      :total-pages="reportsStore.pageCount"
      @page-change="handlePageChange"
    />
  </section>
</template>

<style scoped>
.visibility-toggle-button {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
  padding: 8px 12px;
  background-color: #212429;
  color: #fff;
  border-radius: 6px;
  border: none;
}

.visibility-toggle-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.actions-column {
  display: flex;
  gap: 10px;
  min-width: 458px;
  justify-content: end;
}
.actions-column-header {
  display: flex;
  min-width: 458px;
  justify-content: center;
}
.pay-button {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
  background-color: #28a745;
  color: #fff;
  border: none;
  padding-right: 24px;
  padding-left: 24px;
  padding-bottom: 6px;
  padding-top: 6px;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.2s;
}
.pay-button:hover {
  background-color: #218838;
}
.pay-button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}
.select-container {
  position: relative;
  width: 100%;
}

.select-trigger {
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  background-color: white;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 38px;
}

.select-trigger:hover {
  border-color: #bbb;
}

.select-trigger.is-open {
  border-color: #0d1017;
  box-shadow: 0 0 0 1px #0d1017;
}

.select-arrow {
  transition: transform 0.2s ease;
}

.is-open .select-arrow {
  transform: rotate(180deg);
}

.placeholder {
  color: #000000;
  background-color: #ffffff;
}

.select-search-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: white;
  border: 1px solid #0d1017;
  border-radius: 0.25rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  z-index: 50;
}
.select-search-wra {
  min-height: 43px;
  width: 100%;
}

.select-search-input {
  width: 100%;
  padding: 0.5rem;
  border: none;
  outline: none;
  background: transparent;
  font-size: 1rem;
}

.select-options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background: white;
  border: 1px solid #ddd;
  border-top: none;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.select-option {
  padding: 0.5rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.select-option:hover {
  background-color: #f3f4f6;
}

.select-option.is-selected {
  background-color: #e5e7eb;
}

/* Make input group relative for proper z-index stacking */
.input-group {
  position: relative;
  z-index: 1;
}

.input-group:has(.select-container) {
  z-index: 2;
}

.selection-box {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
}
.selection-box h2 {
  font-size: 28px;
}
.label {
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  margin-top: 16px;
}
a {
  text-decoration: inherit;
  color: inherit;
  cursor: pointer;
}
a:visited {
  text-decoration: inherit;
  color: inherit;
  cursor: pointer;
}
tbody td {
  font-size: 16px;
  font-weight: 500;
}
.column {
  min-width: 208px;
}
.reporte-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 20px;
  margin-bottom: 8px;
}
.reporte-it {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 20px;
  background-color: #ececec;
  margin-bottom: 8px;
}
.reporte-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  padding: 6px 10px;
  border-bottom: 1px solid rgb(160, 160, 160);
}
.reporte-header th {
  font-size: 20px;
  font-weight: 500;
}
.reporte-header .column {
  min-width: 225px;
}
.reporte-row {
  display: flex;
  justify-content: space-between;
}
.page-link {
  color: #000;
}
.page-item.active .page-link {
  background-color: #000;
  border-color: #000;
  color: #fff;
}
.modal-footer {
  border: none;
}
.cerrar-btn {
  background-color: white;
  color: black;
}
.cerrar-btn:hover {
  background-color: rgb(212, 212, 212);
  color: black;
}
.search-button {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
  background-color: #212429;
  color: #fff;
  border: none;
  padding-right: 24px;
  padding-left: 24px;
  padding-bottom: 6px;
  padding-top: 6px;
  border-radius: 6px;
}
.search-but {
  color: #fff;
  background-color: #fff;
  border: none;
  padding-right: 24px;
  padding-left: 80px;
  padding-bottom: 6px;
  padding-top: 6px;
  border-radius: 6px;
}
</style>
