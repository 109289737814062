import '@fortawesome/fontawesome-free/css/all.min.css'

import en from "@/assets/locales/en_US.json"
import es from "@/assets/locales/es_ES.json"
import 'bootstrap'
import { createBootstrap } from 'bootstrap-vue-next'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'
import 'bootstrap/dist/css/bootstrap.min.css'

import { createPinia } from 'pinia'
import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import App from './App.vue'
import './assets/styles/main.css'
import router from './router'
import store from './store'
import { useCoreStore } from './stores/core'

function getSubdomain() {
  const hostType = process.env.VUE_APP_APP_TYPE;
  return hostType
}

const pinia = createPinia();
const i18n = createI18n({
  locale: sessionStorage.getItem('lang') || 'es',
  fallbackLocale: "es", 
  legacy: false,
  messages: { es,en },
})

const app = createApp(App);
app.use(store)
app.use(router)
app.use(pinia)
app.use(i18n)
app.use(createBootstrap())

// Get subdomain and store it
const subdomainStore = useCoreStore();
const subdomain = getSubdomain();
subdomainStore.setSubdomain(subdomain);

app.mount('#app');