<script setup>
import DataTable from "@/components/common/DataTable.vue";
import PaginationControl from "@/components/common/PaginationControl.vue";
import { usePayoutStore } from "@/stores/Payouts";
import { useSalesStore } from "@/stores/Sales";
import { computed, onMounted, ref } from "vue";
import PayoutDialog from "@/components/forms/PayoutDialog .vue";
import { useProductStore } from "@/stores/productStore";
import { showNotification } from "@/composables/useNotification";
import SearchBarFilter from "@/components/common/SearchBarFilter.vue";

const salesStore = useSalesStore();
const payoutStore = usePayoutStore();
const productStore = useProductStore();
const isDialogOpen = ref(false);
const currentPage = ref(1);

const handleProducerSelection = (producer) => {
  payoutStore.getPayouts(1, producer?.id); 
};

const titlesTable = computed(() => [
  {
    width: "",
    title: "Monto",
  },
  {
    width: "w-4/12",
    title: "Fecha",
  },
]);

onMounted(async () => {
  await salesStore.getSales();
  await payoutStore.getPayouts(currentPage.value);
  await productStore.getAllProducts(true);
});

async function handleCreatePayout(amount, user) {
  if (payoutStore.isLoading) return;

  try {
    payoutStore.changeStatus("ready");
    await payoutStore.createPayout(amount, user);
    isDialogOpen.value = false;
    showNotification("Pago creado correctamente", "success");
  } catch (error) {
    console.error("Failed to create payout:", error);
    showNotification("Error al crear el pago", "error");
  } finally {
    await payoutStore.getPayouts(currentPage.value);
  }
}

function handlePageChange(page) {
  currentPage.value = page;
  payoutStore.getPayouts(page);
}

function formatDate(dateString) {
  const date = new Date(dateString);
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
}
</script>

<template>
  <section>
    <div class="main-title">
      <div class="header-container">
        <h2>Pagos</h2>
        <button
          @click="isDialogOpen = true"
          class="create-button btn btn-secondary mt-4 mx-2"
        >
          <i class="fas fa-plus"></i>
          Crear Pago
        </button>
      </div>
    </div>
    <SearchBarFilter
      :list="productStore.producers"
      @item-selected="handleProducerSelection"
      label="Productor"
      prop_name="name"
    />

    <PayoutDialog
      :isOpen="isDialogOpen"
      :isLoading="payoutStore.isLoading"
      :onClose="() => (isDialogOpen = false)"
      :onSubmit="handleCreatePayout"
      :producers="productStore.producers"
    />

    <DataTable
      :noHaveData="payoutStore.data?.length === 0"
      :headers="titlesTable"
    >
      <template #body>
        <tr v-for="(item, index) in payoutStore.data" :key="index">
          <td class="table-cell" v-text="item.amount || '-'" />
          <td class="table-cell" v-text="formatDate(item.created_at) || '-'" />
        </tr>
      </template>
    </DataTable>

    <PaginationControl
      v-if="payoutStore.data?.length"
      :current-page="currentPage"
      :total-pages="payoutStore.pageCount"
      @page-change="handlePageChange"
    />
  </section>
</template>

<style scoped>
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.create-button {
  padding: 0.5rem 1rem;
  background-color: #21262c;
  color: white;
  border-radius: 0.25rem;
  border: none;
  cursor: pointer;
}

.create-button:hover {
  background-color: #2b2d30;
}

.create-button:disabled {
  background-color: #9ca3af;
  cursor: not-allowed;
}

.table-cell {
  padding: 0.75rem;
}

/* Commented out styles for future reference
.search-section {
  display: flex;
  gap: 1rem;
}

.search-section p {
  margin-top: 1rem;
  padding-top: 0.5rem;
}

.filter-container {
  display: flex;
  gap: 1rem;
}

.select-container {
  width: 100%;
}

.select-container p {
  margin-bottom: 0;
}
*/
</style>
