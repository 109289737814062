<template>
  <AppHeader />
  <div class="">
    <div class="row">
      <AppSidebar class="col-md-2 col-lg-2" />
      <main class="col-12 layout-main">
        <div class="content-dash">
          <router-view></router-view>
        </div>
      </main>
    </div>
  </div>
  <AdminLogin v-if="!storeForm.token" />
</template>

<script setup>
import AdminLogin from "@/components/AdminLogin.vue";
import AppHeader from "@/components/HeaderBase.vue";
import AppSidebar from "@/components/SideBar.vue";
import { useForm } from "@/stores/form";

const storeForm = useForm();
</script>